import React, { useState, useEffect } from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { Global, jsx, css } from '@emotion/react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ContextMenuTrigger } from 'react-contextmenu';

import SEO from '../components/SEO';
import ExternalLinkIcon from '../components/ExternalLinkIcon';
import OfflineToast from '../components/OfflineToast';
import Footer from '../components/Footer';
import DPCContextMenu from '../components/DPCContextMenu';
import { desktopMinWidth } from '../constants';
import { shuffle } from '../helper';

const LIMITED_IMAGES = 10;

const photographyLinks = [
  {
    text: 'Keo Photography',
    url: 'https://keophoto.net/',
  },
  {
    text: 'Niral Bhavsar Photography',
    url: 'https://blog.niralbhavsar.com/',
  },
  {
    text: 'KPN Photography',
    url: 'https://www.kpnphotography.com/',
  },
];

// markup
const IndexPage = ({ data }) => {
  const [imagesObj, setImagesObj] = useState([]);
  const [rightClickData, setRightClickData] = useState([]);

  const handleRightClick = (data) => {
    setRightClickData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let [niralbhavsarResponse, keophotoResponse] = await Promise.allSettled([
          fetch('https://blog.niralbhavsar.com/feed.json').then(response => response.json()),
          fetch('https://keophoto.net/featured_images.json').then(response => response.json()),
        ]);
  
        const niralbhavsarImages = niralbhavsarResponse.value?.items.map(({ title, images, url, tags }) => ({
          whom: photographyLinks[1].text,
          title,
          imageSrc: images[0],
          url,
          tags,
        })) || [];
        const niralbhavsarLimitedImages = shuffle(niralbhavsarImages).slice(0, LIMITED_IMAGES);
  
        const keophotoImages = keophotoResponse.value?.images.map(({ title, imageSrc, url }) => ({
          whom: photographyLinks[0].text,
          title,
          imageSrc,
          url,
        })) || [];
        const keophotoLimitedImages = shuffle(keophotoImages).slice(0, LIMITED_IMAGES);
  
        // Randomize
        const randomized = shuffle([...niralbhavsarLimitedImages, ...keophotoLimitedImages]);
        setImagesObj(randomized);
      }
      catch(err) {
        console.log(err);
      };
    }
    fetchData();
    console.log(
      '%c+',
      `
        font-size: 1px;
        padding: 100px 100px;
        background: url("${data.site.siteMetadata.siteUrl}${data.logo.childrenImageSharp[0].fluid.src}");
        background-size: 200px 200px;
        color: transparent;
      `
    );
    console.log(`%c${data.site.siteMetadata.title}`,'color: gray; font-weight: bold; font-family: sans-serif; font-size: 20px');
  }, []);

  return (
    <>
      <Global
        styles={css`
          body {
            background: #000;
            min-height: 90vh;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          @keyframes slideInDown {
            from {
              transform: translate3d(0, -30px, 0);
              visibility: visible;
            }
            to {
              transform: translate3d(0, 0, 0);
            }
          }
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes glow {
            0% {
              text-shadow: 0;
            }
            50% {
              // text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
              text-shadow: #fff 1px 0 20px;
              ;
            }
            to {
              text-shadow: 0;
            }
          }
        `}
      />
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.title}
        imageUrl={`${data.site.siteMetadata.siteUrl}${data.logo.childrenImageSharp[0].fluid.src}`}
        isIndex
      />
      <div
        css={css`
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          align-content: center;
        `}
      >
        <h1
          css={css`
            color: #fff;
            // sr-only
            position:absolute;
            left:-10000px;
            top:auto;
            width:1px;
            height:1px;
            overflow:hidden;
          `}
        >
          {data.site.siteMetadata.title}
        </h1>
        <div
          css={css`
            width: 75vw;
            max-width: 500px;
            // Desktop screens
              @media (min-width: ${desktopMinWidth}) {
                width: 50vw;
              }
            // Fade in
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: fadeIn;
          `}
        >
          <Img
            fluid={data.logo.childrenImageSharp[0].fluid}
            alt={`${data.site.siteMetadata.title} Logo`}
          />
        </div>
      </div>
      <ul
        css={css`
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // Desktop screens
          @media (min-width: ${desktopMinWidth}) {
            flex-direction: row;
          }

          // Animate in
          animation-duration: 0.5s;
          animation-fill-mode: both;
          animation-name: slideInDown;
        `}
      >
        {photographyLinks.map(link => (
            <li
              css={css`
                list-style-type: none;
                padding: 10px;
                margin: 10px;
                display: flex;
                justify-content: center;
                font-family: 'Alegreya Sans SC', sans-serif;
                font-size: 26px;
                animation: glow 1s ease-in-out 1 alternate;
                animation-delay: 1s;
              `}
              key={link.url}
            >
                <a
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={css`
                    display: flex;
                    text-align: center;
                    color: #e8eaed;
                    transition: 0.3s;
                    &:hover {
                      color: #b1b1b1;
                    }
                    &:active {
                      color: #7e7e7e;
                    }
                    &:hover div {
                      visibility: visible;
                    }
                  `}
                >
                  {link.text}
                  <div
                    css={css`
                      width: 20px;
                      height 20px;
                      padding: 2px;
                      margin-left: 3px;
                      visibility: hidden;
                    `}
                  >
                    <ExternalLinkIcon color="#b1b1b1" />
                  </div>
                </a>
            </li>
          ))}
      </ul>

      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        {imagesObj.map(image => (
          <>
            {image.imageSrc && ( 
              <ContextMenuTrigger id="dark-photo-corp-images">
              <div key={image.imageSrc} onContextMenu={() => handleRightClick(image)}>
                <a
                  href={image.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={image.imageSrc}
                    height={200}
                    alt={image.title}
                    title={`${image.title} (${image.whom})`}
                    css={css`
                      vertical-align: top;
                      height: 100px;
                      // Desktop screens
                      @media (min-width: ${desktopMinWidth}) {
                        height: 200px;
                      }
                    `}
                  />
                </a>
              </div>
              </ContextMenuTrigger>
            )}
          </>
        ))}
      </div>
      <DPCContextMenu
        copyright={data.site.siteMetadata.title}
        rightClickData={rightClickData}
      />
      <Footer title={data.site.siteMetadata.title} />
      <OfflineToast />
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    logo: file(relativePath: {eq: "dark_photo_corp_logo.jpeg"}) {
      childrenImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
