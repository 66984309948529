import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import ExternalLinkIcon from '../components/ExternalLinkIcon';
import '../styles/react-contextmenu.css';

// Inspired By: https://codesandbox.io/s/6y2yv0471n?file=/src/index.js
const DPCContextMenu = ({ copyright, rightClickData }) => {
  const year = new Date().getFullYear();
  const { whom, title, url } = rightClickData;
  const openInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <ContextMenu id="dark-photo-corp-images">
      <MenuItem onClick={() => {openInNewTab(url)}}>
        <div
          css={css`
            display: flex;
          `}
        >
          <div>{title} by {whom}</div>
          <div
            css={css`
              width: 16px;
              height 16px;
              padding: 2px;
              margin-left: 3px;
            `}
          >
            <ExternalLinkIcon color="#000" />
          </div>
        </div>
      </MenuItem>
      <MenuItem divider />
      <MenuItem>
        © {year} {copyright}
      </MenuItem>
    </ContextMenu>
  );
};

export default DPCContextMenu;
